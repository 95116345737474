<template>
  <div class="compony_introduce_container w">
    <div class="compony_introduce_content">
      <h3 class="title">公司介绍</h3>
      <div class="content">
        2016年8月，上海泉邦网络科技有限公司正式成立，总部设于“中国经济中心上海”，由互联网+商学教育的领军者马煜超老师，携手知名企业咨询师，实战企业家，行业大咖等合力创办。公司以教育为入口，为广大中小微型企业提供落地解决方案，涉足顶层设计，商业模式，股权激励，招商融资，团队打造等众多领域。目前拥有4大营运中心，5家省级分公司，96余家城市分公司，200多位市场合伙人。
      </div>
      <div class="data">
        <div class="data_item">
          <h3 class="item_title">
            <div class="num">4</div>
            <div class="character">大</div>
          </h3>
          <div class="item_name">营运公司</div>
        </div>
        <div class="data_item">
          <h3 class="item_title">
            <div class="num">5</div>
            <div class="character">家</div>
          </h3>
          <div class="item_name">省级分公司</div>
        </div>
        <div class="data_item">
          <h3 class="item_title">
            <div class="num">96</div>
            <div class="character">家</div>
          </h3>
          <div class="item_name">城市分公司</div>
        </div>
        <div class="data_item">
          <h3 class="item_title">
            <div class="num">200</div>
            <div class="character">位</div>
          </h3>
          <div class="item_name">合伙人</div>
        </div>
      </div>
    </div>
    <div class="compony_introduce_img">
      <img src="@/assets/images/about/company-reception.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponyIntroduce'
}
</script>

<style lang="less" scoped>
.compony_introduce_container {
  padding: 77px 181px 85px;
  display: flex;
  margin-bottom: 85px;
}
.compony_introduce_content {
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    line-height: 44px;
    margin-bottom: 23px;
  }
  .content {
    width: 757px;
    height: 288px;
    font-size: 24px;
    font-weight: 400;
    color: #010343;
    line-height: 48px;
  }
  .data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 757px;
  }
  .data_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 35px;
    padding-right: 155px;
    margin-top: 90px;
    // padding-left: 35px;
  }
  .item_title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    margin-left: 23px;
    .num {
      font-size: 64px;
      font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
      font-weight: 700;
      color: #196de6;
    }
    .character {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #196de6;
      line-height: 39px;
      margin-bottom: 10px;
      margin-left: 13px;
    }
  }
  .item_name {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}
.compony_introduce_img {
  width: 757px;
  height: 821px;
  margin-top: 44px;
  margin-left: 43px;
}
</style>
