<template>
  <div class="company_video_container w">
    <!-- need  muted="muted" -->
    <video
      class="video"
      src="http://res.quanbangcloud.com/videos/00/000041d0127c46dbbda2f3cd6f5e1cfb.mp4"
      poster="@/assets/images/video_cover.png"
      loop="loop"
      ref="video"
      object-fit="contain"
      @click="handlePlay(false)"
    ></video>
    <div class="play_btn" @click="handlePlay(true)" v-if="showPlayBtn">
      <img src="@/assets/images/about/play_btn.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyVideo',
  data() {
    return {
      showPlayBtn: true
    }
  },
  mounted() {
    // console.log(this.$refs.video.play())
  },
  methods: {
    handlePlay(flag) {
      if (flag) {
        this.$refs.video.play()
        this.showPlayBtn = false
        return
      }
      this.$refs.video.pause()
      this.showPlayBtn = true
    }
  }
}
</script>

<style lang="less" scoped>
.company_video_container {
  height: 1080px;
  position: relative;
  .video {
    width: 100%;
    height: 100%;
    z-index: -10;
    object-fit: cover;
  }
  .play_btn {
    width: 96px;
    height: 96px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
