<template>
  <div class="company_news_container w">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="公司新闻" name="first">
        <CompanyNewsPane></CompanyNewsPane>
      </el-tab-pane>
      <el-tab-pane label="媒体动态" name="second">
        <CompanyDynamic></CompanyDynamic>
      </el-tab-pane>
      <el-tab-pane label="启动大会" name="third">
        <CompanyStartPanel></CompanyStartPanel>
      </el-tab-pane>
    </el-tabs>
    <div class="line line_one"></div>
    <div class="line line_two"></div>
  </div>
</template>

<script>
import CompanyNewsPane from './company-news-pane.vue'
import CompanyDynamic from './company-dynamic-panel.vue'
import CompanyStartPanel from './company-started-panel.vue'
export default {
  name: 'CompanyNews',
  components: {
    CompanyNewsPane,
    CompanyDynamic,
    CompanyStartPanel
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick() {}
  }
}
</script>

<style lang="less" scoped>
.company_news_container {
  padding: 91px 181px 0;
  position: relative;
  height: 725px;
  ::v-deep .el-tabs__header {
    margin-bottom: 101px;
    .el-tabs__nav-wrap {
      &::after {
        display: none;
      }
    }
    .el-tabs__active-bar {
      display: none;
    }

    .el-tabs__item {
      position: relative;
      font-size: 32px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #010343;
      padding: 0;
      margin-right: 36px;
      // &::after {
      //   content: '';
      //   height: 21px;
      //   border-right: 2px solid #979797;
      //   position: absolute;
      //   top: 12px;
      //   left: -18px;
      //   right: 200px;
      // }
    }
    .el-tabs__item:nth-child(2) {
      padding-left: 0;
      &::after {
        content: '';
        border-right: 0px solid #000;
      }
    }
    .is-active {
      font-weight: 500;
      color: #010343;
    }
  }
  .line {
    position: absolute;
    z-index: 100;
    height: 21px;
    border-right: 2px solid #979797;
  }
  .line_one {
    top: 104px;
    left: 327px;
  }
  .line_two {
    top: 104px;
    left: 490px;
  }
}
</style>
