<template>
  <div class="about_company_container">
    <!-- 顶部导航占位符 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 公司介绍标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 公司介绍视频 -->
    <CompanyVideo></CompanyVideo>

    <!-- 公司新闻 -->
    <CompanyNews></CompanyNews>

    <!-- 公司介绍 -->
    <ComponyIntroduce></ComponyIntroduce>

    <!-- 泉邦家族 -->
    <QbFamily class="qb_family"></QbFamily>
  </div>
</template>

<script>
import CompanyVideo from './components/company-video.vue'
import CompanyNews from './components/company-news.vue'
import ComponyIntroduce from './components/compony-introduce.vue'
export default {
  name: 'AboutCompany',
  components: {
    CompanyVideo,
    CompanyNews,
    ComponyIntroduce
  },
  data() {
    return {
      title: '成为企业家精准式学习第一品牌',
      complement: '让企业家学习更高效'
    }
  }
}
</script>

<style lang="less" scoped></style>
