<template>
  <div class="news_pane_container">
    <div class="pane_cover">
      <img :src="dynamicList[0].cover_image" alt="" />
    </div>
    <div class="pane_content">
      <div
        class="pane_news_top"
        @click="$router.push('/new/dynamic/' + dynamicList[0].dynamicId)"
      >
        <h3 class="title">{{ dynamicList[0].title }}</h3>
        <span class="time">{{ dynamicList[0].time }}</span>
        <div class="content">
          {{ dynamicList[0].introduction }}
        </div>
      </div>
      <div class="line"></div>
      <ul class="pane_news_other">
        <li v-for="item in dynamicList.slice(1, 4)" :key="item.dynamicId">
          <div
            class="item"
            @click="$router.push('/new/dynamic/' + item.dynamicId)"
          >
            <h3 class="title">
              {{ item.title }}
            </h3>
            <div class="time">{{ item.time }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsPane',
  data() {
    return {
      dynamicList: [
        {
          dynamicId: 1,
          time: '2021-1-1',
          title: '泉邦集团上海内部总训 | 一切以结果为导向',
          cover_image: require('@/assets/images/about/bg.jpg'),
          introduction:
            '泉邦集团上海总部今日开展以“执行力”为主题的团队内训。相信在未来的工作中，泉邦家人们都能拼搏努力，全力以赴拿到结果。'
        },
        {
          dynamicId: 2,
          time: '2021-1-1',
          title: '泉邦集团上海内部总训 | 一切以结果为导向',
          cover_image: require('@/assets/images/about/bg.jpg'),
          introduction:
            '泉邦集团上海总部今日开展以“执行力”为主题的团队内训。相信在未来的工作中，泉邦家人们都能拼搏努力，全力以赴拿到结果。'
        },
        {
          dynamicId: 3,
          time: '2021-1-1',
          title: '泉邦集团上海内部总训 | 一切以结果为导向',
          cover_image: require('@/assets/images/about/bg.jpg'),
          introduction:
            '泉邦集团上海总部今日开展以“执行力”为主题的团队内训。相信在未来的工作中，泉邦家人们都能拼搏努力，全力以赴拿到结果。'
        },
        {
          dynamicId: 4,
          time: '2021-1-1',
          title: '泉邦集团上海内部总训 | 一切以结果为导向',
          cover_image: require('@/assets/images/about/bg.jpg'),
          introduction:
            '泉邦集团上海总部今日开展以“执行力”为主题的团队内训。相信在未来的工作中，泉邦家人们都能拼搏努力，全力以赴拿到结果。'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.news_pane_container {
  display: flex;
  .pane_cover {
    width: 433px;
    height: 415px;
    margin-right: 61px;
  }
}

.pane_content {
  width: 1064px;
  .pane_news_top {
    padding-top: 7px;
    cursor: pointer;
    .title {
      font-size: 32px;
      font-weight: 500;
      color: #010343;
      line-height: 44px;
    }
    .time {
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      margin-bottom: 24px;
    }
    .content {
      font-size: 24px;
      font-weight: 400;
      line-height: 48px;
      margin-bottom: 21px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}

.line {
  width: 100%;
  width: 1063px;
  height: 1px;
  border: 1px solid #979797;
  margin-bottom: 36px;
}

.pane_news_other {
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    cursor: pointer;
    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 48px;
    }
    .time {
      font-size: 24px;
      font-weight: 300;
      line-height: 48px;
    }
  }
}
</style>
